import type { ProductCardDetails } from "./types";
export const chromium3Prime: ProductCardDetails = {
  name: "Chromium 3' Single Cell Gene Expression",
  type: "assay",
  versions: [
    {
      compatibleProducts: [
        {
          display: "Chromium X",
          name: "Chromium X Series",
          version: "Chromium X",
          url: "/instruments/chromium-x",
        },
        {
          display: "Chromium Xo",
          name: "Chromium X Series",
          version: "Chromium Xo",
          url: "/instruments/chromium-xo",
        },
      ],
      name: "v4 GEM-X Multiplex",
      tag: "New",
      subgroups: [
        {
          details: [
            {
              id: "1000494",
              name: "Chromium Nuclei Isolation with RNase Inhibitor Kit, 16rxns",
              type: "RGT",
            },
          ],
          name: "Nuclei Isolation (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000747",
              name: "Kit, GEM-X OCM 3' Chip Kit v4 4-plex, 2 chips",
              type: "CHIP",
            },
          ],
          name: "Chip",
          skus: [],
        },
        {
          details: [
            {
              id: "1000215",
              name: "Dual Index Kit TT Set A 96 rxns",
              type: "RGT",
            },
            {
              id: "1000242",
              name: "Dual Index Kit NT Set A, 96 rxn",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000779",
              name: "GEM-X Universal 3’ Gene Expression v4 4-plex, 16 samples",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
        {
          details: [
            {
              id: "1000694",
              name: "Library Construction Kit C, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Library Construction Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000702",
              name: "Chromium GEM-X Single Cell 3 Feature Barcode Kit v4, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Feature Barcoding Kit (optional)",
          skus: [],
        },
      ],
    },
    {
      compatibleProducts: [
        {
          display: "Chromium X",
          name: "Chromium X Series",
          version: "Chromium X",
          url: "/instruments/chromium-x",
        },
        {
          display: "Chromium iX",
          name: "Chromium X Series",
          version: "Chromium iX",
          url: "/instruments/chromium-family",
        },
        {
          display: "Chromium Xo",
          name: "Chromium X Series",
          version: "Chromium Xo",
          url: "/instruments/chromium-xo",
        },
      ],
      name: "v4 GEM-X",
      subgroups: [
        {
          details: [
            {
              id: "1000494",
              name: "Chromium Nuclei Isolation with RNase Inhibitor Kit, 16rxns",
              type: "RGT",
            },
          ],
          name: "Nuclei Isolation (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000690",
              name: "Chromium GEM-X Single Cell 3' Chip Kit v4, 4 chips",
              type: "CHIP",
            },
          ],
          name: "Chip",
          skus: [],
        },
        {
          details: [
            {
              id: "1000242",
              name: "Dual Index Kit NT Set A, 96 rxn",
              type: "RGT",
            },
            {
              id: "1000215",
              name: "Dual Index Kit TT Set A 96 rxns",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000686",
              name: "Chromium GEM-X Single Cell 3' Kit v4, 4 rxns",
              type: "RGT",
            },
            {
              id: "1000691",
              name: "Chromium GEM-X Single Cell 3' Kit v4, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
        {
          details: [
            {
              id: "1000694",
              name: "Library Construction Kit C, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Library Construction Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000702",
              name: "Chromium GEM-X Single Cell 3 Feature Barcode Kit v4, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Feature Barcoding Kit (optional)",
          skus: [],
        },
      ],
    },
    {
      compatibleProducts: [
        {
          display: "Chromium X",
          name: "Chromium X Series",
          version: "Chromium X",
          url: "/instruments/chromium-x",
        },
        {
          display: "Chromium iX",
          name: "Chromium X Series",
          version: "Chromium iX",
          url: "/instruments/chromium-family",
        },
      ],
      name: "v3.1 Standard Throughput",
      subgroups: [
        {
          details: [
            {
              id: "1000494",
              name: "Chromium Nuclei Isolation with RNase Inhibitor Kit, 16rxns",
              type: "RGT",
            },
          ],
          name: "Nuclei Isolation (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000127",
              name: "Chromium Next GEM Chip G Single Cell Kit, 16 rxns",
              type: "CHIP",
            },
            {
              id: "1000120",
              name: "Chromium Next GEM Chip G Single Cell Kit, 48 rxns",
              type: "CHIP",
            },
          ],
          name: "Chip",
          skus: [],
        },
        {
          details: [
            {
              id: "1000242",
              name: "Dual Index Kit NT Set A, 96 rxn",
              type: "RGT",
            },
            {
              id: "1000215",
              name: "Dual Index Kit TT Set A 96 rxns",
              type: "RGT",
            },
            {
              id: "1000243",
              name: "Dual Index Kit NN Set A, 96 rxn",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000269",
              name: "Chromium Next GEM Single Cell 3' Kit v3.1, 4 rxns",
              type: "RGT",
            },
            {
              id: "1000268",
              name: "Chromium Next GEM Single Cell 3' Kit v3.1, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
        {
          details: [
            {
              id: "1000190",
              name: "Library Construction Kit, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Library Construction Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000262",
              name: "3' Feature Barcode Kit, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Feature Barcoding Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000261",
              name: "3' CellPlex Kit Set A, 48 rxns",
              type: "RGT",
            },
          ],
          name: "CellPlex Kit (optional)",
          skus: [],
        },
      ],
    },
    {
      compatibleProducts: [
        {
          display: "Chromium X",
          name: "Chromium X Series",
          version: "Chromium X",
          url: "/instruments/chromium-x",
        },
      ],
      name: "v3.1 High Throughput",
      subgroups: [
        {
          details: [
            {
              id: "1000494",
              name: "Chromium Nuclei Isolation with RNase Inhibitor Kit, 16rxns",
              type: "RGT",
            },
          ],
          name: "Nuclei Isolation (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000371",
              name: "Chromium Next GEM Chip M Single Cell Kit, 16 rxns",
              type: "CHIP",
            },
          ],
          name: "Chip (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000242",
              name: "Dual Index Kit NT Set A, 96 rxn",
              type: "RGT",
            },
            {
              id: "1000215",
              name: "Dual Index Kit TT Set A 96 rxns",
              type: "RGT",
            },
            {
              id: "1000243",
              name: "Dual Index Kit NN Set A, 96 rxn",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000370",
              name: "Chromium Next GEM Single Cell 3' HT Kit v3.1, 8 rxns",
              type: "RGT",
            },
            {
              id: "1000348",
              name: "Chromium Next GEM Single Cell 3' HT Kit v3.1, 48 rxns",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
        {
          details: [
            {
              id: "1000352",
              name: "Library Construction Kit, 48 rxns",
              type: "RGT",
            },
          ],
          name: "Library Construction Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000262",
              name: "3' Feature Barcode Kit, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Feature Barcoding Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000261",
              name: "3' CellPlex Kit Set A, 48 rxns",
              type: "RGT",
            },
          ],
          name: "CellPlex Kit (optional)",
          skus: [],
        },
      ],
    },
    {
      compatibleProducts: [],
      name: "v3.1 Automated",
      subgroups: [
        {
          details: [
            {
              id: "1000146",
              name: "Chromium Next GEM Chip G Automated Single Cell Kit, 16 rxns",
              type: "CHIP",
            },
            {
              id: "1000136",
              name: "Chromium Next GEM Chip G Automated Single Cell Kit, 48 rxns",
              type: "CHIP",
            },
          ],
          name: "Chip",
          skus: [],
        },
        {
          details: [
            {
              id: "1000215",
              name: "Dual Index Kit TT Set A 96 rxns",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000147",
              name: "Chromium Next GEM Automated Single Cell 3' Library & Gel Bead Kit v3.1, 4 rxns",
              type: "RGT",
            },
            {
              id: "1000141",
              name: "Chromium Next GEM Automated Single Cell 3' Library & Gel Bead Kit v3.1, 24 rxns",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
        {
          details: [
            {
              id: "1000424",
              name: "Chromium Next GEM Automated Single Cell 3' cDNA Kit v3.1, 24 rxns",
              type: "RGT",
            },
            {
              id: "1000428",
              name: "Automated Library Construction Kit, 24 rxns",
              type: "RGT",
            },
          ],
          name: "Modular Components (optional)",
          skus: [],
        },
      ],
    },
  ],
};

export const chromium5Prime: ProductCardDetails = {
  name: "Chromium 5' Single Cell Gene Expression",
  type: "assay",
  versions: [
    {
      compatibleProducts: [
        {
          display: "Chromium X",
          name: "Chromium X Series",
          version: "Chromium X",
          url: "/instruments/chromium-x",
        },
      ],
      name: "v3 GEM-X Multiplex",
      tag: "New",
      subgroups: [
        {
          details: [
            {
              id: "1000494",
              name: "Chromium Nuclei Isolation with RNase Inhibitor Kit, 16rxns",
              type: "RGT",
            },
          ],
          name: "Nuclei Isolation (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000748",
              name: "Kit, GEM-X OCM 5' Chip Kit v3 4-plex, 2 chips",
              type: "CHIP",
            },
          ],
          name: "Chip",
          skus: [],
        },
        {
          details: [
            {
              id: "1000215",
              name: "Dual Index Kit TT Set A 96 rxns",
              type: "RGT",
            },
            {
              id: "1000242",
              name: "Dual Index Kit NT Set A, 96 rxn",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000780",
              name: "GEM-X Universal 5’ Gene Expression v3 4-plex, 16 samples",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
        {
          details: [
            {
              id: "1000252",
              name: "Chromium Single Cell Human TCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000253",
              name: "Chromium Single Cell Human BCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000254",
              name: "Chromium Single Cell Mouse TCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000255",
              name: "Chromium Single Cell Mouse BCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000694",
              name: "Library Construction Kit C, 16 rxns",
              type: "RGT",
            },
          ],
          name: "V(D)J Amplification Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000451",
              name: "5' CRISPR Kit, 16 rxns",
              type: "RGT",
            },
          ],
          name: "CRISPR Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000703",
              name: "Chromium GEM-X Single Cell 5 Feature Barcode Kit v3, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Feature Barcoding Kit (optional)",
          skus: [],
        },
      ],
    },
    {
      compatibleProducts: [
        {
          display: "Chromium X",
          name: "Chromium X Series",
          version: "Chromium X",
          url: "/instruments/chromium-x",
        },
        {
          display: "Chromium iX",
          name: "Chromium X Series",
          version: "Chromium iX",
          url: "/instruments/chromium-family",
        },
      ],
      name: "v3 GEM-X",
      subgroups: [
        {
          details: [
            {
              id: "1000494",
              name: "Chromium Nuclei Isolation with RNase Inhibitor Kit, 16rxns",
              type: "RGT",
            },
          ],
          name: "Nuclei Isolation (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000698",
              name: "Chromium GEM-X Single Cell 5' Chip Kit v3, 4 chips",
              type: "CHIP",
            },
          ],
          name: "Chip",
          skus: [],
        },
        {
          details: [
            {
              id: "1000215",
              name: "Dual Index Kit TT Set A 96 rxns",
              type: "RGT",
            },
            {
              id: "1000250",
              name: "Dual Index Kit TN Set A, 96 rxn",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000695",
              name: "Chromium GEM-X Single Cell 5' Kit v3, 4 rxns",
              type: "RGT",
            },
            {
              id: "1000699",
              name: "Chromium GEM-X Single Cell 5' Kit v3, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
        {
          details: [
            {
              id: "1000252",
              name: "Chromium Single Cell Human TCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000253",
              name: "Chromium Single Cell Human BCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000254",
              name: "Chromium Single Cell Mouse TCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000255",
              name: "Chromium Single Cell Mouse BCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000694",
              name: "Library Construction Kit C, 16 rxns",
              type: "RGT",
            },
          ],
          name: "V(D)J Amplification Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000451",
              name: "5' CRISPR Kit, 16 rxns",
              type: "RGT",
            },
          ],
          name: "CRISPR Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000703",
              name: "Chromium GEM-X Single Cell 5 Feature Barcode Kit v3, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Feature Barcoding Kit (optional)",
          skus: [],
        },
      ],
    },
    {
      compatibleProducts: [
        {
          display: "Chromium X",
          name: "Chromium X Series",
          version: "Chromium X",
          url: "/instruments/chromium-x",
        },
        {
          display: "Chromium iX",
          name: "Chromium X Series",
          version: "Chromium iX",
          url: "/instruments/chromium-family",
        },
      ],
      name: "v2 Standard Throughput",
      subgroups: [
        {
          details: [
            {
              id: "1000494",
              name: "Chromium Nuclei Isolation with RNase Inhibitor Kit, 16rxns",
              type: "RGT",
            },
          ],
          name: "Nuclei Isolation (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000287",
              name: "Chromium Next GEM Chip K Single Cell Kit, 16 rxns",
              type: "CHIP",
            },
            {
              id: "1000286",
              name: "Chromium Next GEM Chip K Single Cell Kit, 48 rxns",
              type: "CHIP",
            },
          ],
          name: "Chip",
          skus: [],
        },
        {
          details: [
            {
              id: "1000215",
              name: "Dual Index Kit TT Set A 96 rxns",
              type: "RGT",
            },
            {
              id: "1000250",
              name: "Dual Index Kit TN Set A, 96 rxn",
              type: "RGT",
            },
            {
              id: "1000251",
              name: "Dual Index Kit TS Set A, 96 rxn",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000265",
              name: "Chromium Next GEM Single Cell 5' Kit v2, 4 rxns",
              type: "RGT",
            },
            {
              id: "1000263",
              name: "Chromium Next GEM Single Cell 5' Kit v2, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
        {
          details: [
            {
              id: "1000252",
              name: "Chromium Single Cell Human TCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000253",
              name: "Chromium Single Cell Human BCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000254",
              name: "Chromium Single Cell Mouse TCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000255",
              name: "Chromium Single Cell Mouse BCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000190",
              name: "Library Construction Kit, 16 rxns",
              type: "RGT",
            },
          ],
          name: "V(D)J Amplification Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000451",
              name: "5' CRISPR Kit, 16 rxns",
              type: "RGT",
            },
          ],
          name: "CRISPR Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000541",
              name: "5' Feature Barcode Kit, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Feature Barcoding Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000539",
              name: "Chromium, Single Cell 5' BEAM Core Kit, PE, Set A 128 rxns",
              type: "RGT",
            },
            {
              id: "1000542",
              name: "Chromium Human MHC Class I A0201 Monomer Kit, 32 rxns",
              type: "RGT",
            },
            {
              id: "1000543",
              name: "Chromium Human MHC Class I A1101 Monomer Kit, 32 rxns",
              type: "RGT",
            },
            {
              id: "1000544",
              name: "Chromium Human MHC Class I B0702 Monomer Kit, 32 rxns",
              type: "RGT",
            },
            {
              id: "1000545",
              name: "Chromium Human MHC Class I A2402 Monomer Kit, 32 rxns",
              type: "RGT",
            },
            {
              id: "1000546",
              name: "Chromium Mouse MHC Class I H2Kb Monomer Kit 32 rxns",
              type: "RGT",
            },
          ],
          name: "Barcode Enabled Antigen Mapping Kit (optional, discontinued Dec 31, 2024)",
          skus: [],
        },
      ],
    },
    {
      compatibleProducts: [
        {
          display: "Chromium X",
          name: "Chromium X Series",
          version: "Chromium X",
          url: "/instruments/chromium-x",
        },
      ],
      name: "v2 High Throughput",
      subgroups: [
        {
          details: [
            {
              id: "1000375",
              name: "Chromium Next GEM Chip N Single Cell Kit, 16 rxns",
              type: "CHIP",
            },
          ],
          name: "Chip",
          skus: [],
        },
        {
          details: [
            {
              id: "1000215",
              name: "Dual Index Kit TT Set A 96 rxns",
              type: "RGT",
            },
            {
              id: "1000250",
              name: "Dual Index Kit TN Set A, 96 rxn",
              type: "RGT",
            },
            {
              id: "1000251",
              name: "Dual Index Kit TS Set A, 96 rxn",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000374",
              name: "Chromium Next GEM Single Cell 5' HT Kit v2, 8 rxns",
              type: "RGT",
            },
            {
              id: "1000356",
              name: "Chromium Next GEM Single Cell 5' HT Kit v2, 48 rxns",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
        {
          details: [
            {
              id: "1000252",
              name: "Chromium Single Cell Human TCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000253",
              name: "Chromium Single Cell Human BCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000254",
              name: "Chromium Single Cell Mouse TCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000255",
              name: "Chromium Single Cell Mouse BCR Amplification Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000352",
              name: "Library Construction Kit, 48 rxns",
              type: "RGT",
            },
          ],
          name: "V(D)J Amplification Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000451",
              name: "5' CRISPR Kit, 16 rxns",
              type: "RGT",
            },
          ],
          name: "CRISPR Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000541",
              name: "5' Feature Barcode Kit, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Feature Barcoding Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000539",
              name: "Chromium, Single Cell 5' BEAM Core Kit, PE, Set A 128 rxns",
              type: "RGT",
            },
            {
              id: "1000542",
              name: "Chromium Human MHC Class I A0201 Monomer Kit, 32 rxns",
              type: "RGT",
            },
            {
              id: "1000543",
              name: "Chromium Human MHC Class I A1101 Monomer Kit, 32 rxns",
              type: "RGT",
            },
            {
              id: "1000544",
              name: "Chromium Human MHC Class I B0702 Monomer Kit, 32 rxns",
              type: "RGT",
            },
            {
              id: "1000545",
              name: "Chromium Human MHC Class I A2402 Monomer Kit, 32 rxns",
              type: "RGT",
            },
            {
              id: "1000546",
              name: "Chromium Mouse MHC Class I H2Kb Monomer Kit 32 rxns",
              type: "RGT",
            },
          ],
          name: "Barcode Enabled Antigen Mapping Kit (optional, discontinued Dec 31, 2024)",
          skus: [],
        },
      ],
    },
    {
      compatibleProducts: [],
      name: "v2 Automated",
      subgroups: [
        {
          details: [
            {
              id: "1000297",
              name: "Chromium Next GEM Chip K Automated Single Cell Kit, 16 rxns",
              type: "CHIP",
            },
            {
              id: "1000289",
              name: "Chromium Next GEM Chip K Automated Single Cell Kit, 48 rxns",
              type: "CHIP",
            },
          ],
          name: "Chip",
          skus: [],
        },
        {
          details: [
            {
              id: "1000215",
              name: "Dual Index Kit TT Set A 96 rxns",
              type: "RGT",
            },
            {
              id: "1000250",
              name: "Dual Index Kit TN Set A, 96 rxn",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000298",
              name: "Chromium Next GEM Automated Single Cell 5' Kit v2, 4 rxns",
              type: "RGT",
            },
            {
              id: "1000290",
              name: "Chromium Next GEM Automated Single Cell 5' Kit v2, 24 rxns",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
        {
          details: [
            {
              id: "1000300",
              name: "Chromium Automated Single Cell Human TCR Amplification & Library Construction Kit, 24 rxns",
              type: "RGT",
            },
            {
              id: "1000305",
              name: "Chromium Automated Single Cell Human BCR Amplification & Library Construction Kit, 24 rxns",
              type: "RGT",
            },
            {
              id: "1000310",
              name: "Chromium Automated Single Cell Mouse TCR Amplification & Library Construction Kit, 24 rxns",
              type: "RGT",
            },
            {
              id: "1000311",
              name: "Chromium Automated Single Cell Mouse BCR Amplification & Library Construction Kit, 24 rxns",
              type: "RGT",
            },
          ],
          name: "V(D)J Amplification Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000455",
              name: "Chromium Automated Single Cell 5' Feature Barcode Library Construction Kit",
              type: "RGT",
            },
          ],
          name: "Feature Barcoding Kit (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000425",
              name: "Chromium Next GEM Automated Single Cell 5' cDNA Kit v2, 24 rxns",
              type: "RGT",
            },
            {
              id: "1000428",
              name: "Automated Library Construction Kit, 24 rxns",
              type: "RGT",
            },
          ],
          name: "Modular Components (optional)",
          skus: [],
        },
      ],
    },
  ],
};

export const chromiumFlex: ProductCardDetails = {
  name: "Chromium Flex Gene Expression",
  type: "assay",
  versions: [
    {
      compatibleProducts: [
        {
          display: "Chromium X",
          name: "Chromium X Series",
          version: "Chromium X",
          url: "/instruments/chromium-x",
        },
        {
          display: "Chromium iX",
          name: "Chromium X Series",
          version: "Chromium iX",
          url: "/instruments/chromium-family",
        },
      ],
      name: "GEM-X",
      tag: "New",
      subgroups: [
        {
          details: [
            {
              id: "1000494",
              name: "Chromium Nuclei Isolation with RNase Inhibitor Kit, 16rxns",
              type: "RGT",
            },
          ],
          name: "Nuclei Isolation (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000781",
              name: "GEM-X Flex Sample Preparation v2 Kit, 48 rxns",
              type: "RGT",
            },
          ],
          name: "Sample Prep Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000828",
              name: "GEM-X Flex Supplemental Wash Kit, 64 rxns",
              type: "RGT",
            },
          ],
          name: "Individual Wash Workflow (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000791",
              name: "GEM-X Flex Gene Expression Chip Kit, 4 chips",
              type: "CHIP",
            },
          ],
          name: "Chip",
          skus: [],
        },
        {
          details: [
            {
              id: "1000251",
              name: "Dual Index Kit TS Set A, 96 rxn",
              type: "RGT",
            },
            {
              id: "1000215",
              name: "Dual Index Kit TT Set A 96 rxns",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000792",
              name: "GEM-X Flex Gene Expression Human, 4 samples",
              type: "RGT",
            },
            {
              id: "1000793",
              name: "GEM-X Flex Gene Expression Human 4-plex, 16 samples",
              type: "RGT",
            },
            {
              id: "1000794",
              name: "GEM-X Flex Gene Expression Human 16-plex, 64 samples",
              type: "RGT",
            },
            {
              id: "1000829",
              name: "GEM-X Flex Gene Expression Human n-plex, 64 samples",
              type: "RGT",
            },
            {
              id: "1000795",
              name: "GEM-X Flex Gene Expression Human 16-plex, 256 samples",
              type: "RGT",
            },
            {
              id: "1000796",
              name: "GEM-X Flex Gene Expression Mouse, 4 samples",
              type: "RGT",
            },
            {
              id: "1000797",
              name: "GEM-X Flex Gene Expression Mouse 4-plex, 16 samples",
              type: "RGT",
            },
            {
              id: "1000798",
              name: "GEM-X Flex Gene Expression Mouse 16-plex, 64 samples",
              type: "RGT",
            },
            {
              id: "1000831",
              name: "GEM-X Flex Gene Expression Mouse n-plex, 64 samples",
              type: "RGT",
            },
            {
              id: "1000799",
              name: "GEM-X Flex Gene Expression Mouse 16-plex, 256 samples",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
        {
          details: [
            {
              id: "1000628",
              name: "Fixed RNA Feature Barcode Multiplexing Kit, 64 rxns",
              type: "RGT",
            },
          ],
          name: "Feature Barcoding Kit (optional)",
          skus: [],
        },
      ],
    },
    {
      compatibleProducts: [
        {
          display: "Chromium X",
          name: "Chromium X Series",
          version: "Chromium X",
          url: "/instruments/chromium-x",
        },
        {
          display: "Chromium iX",
          name: "Chromium X Series",
          version: "Chromium iX",
          url: "/instruments/chromium-family",
        },
      ],
      name: "Next GEM",
      subgroups: [
        {
          details: [
            {
              id: "1000494",
              name: "Chromium Nuclei Isolation with RNase Inhibitor Kit, 16rxns",
              type: "RGT",
            },
          ],
          name: "Nuclei Isolation (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000414",
              name: "Chromium Next GEM Single Cell Fixed RNA Sample Preparation Kit, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Sample Prep Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000422",
              name: "Chromium Next GEM Chip Q Single Cell Kit, 16 rxns",
              type: "CHIP",
            },
            {
              id: "1000418",
              name: "Chromium Next GEM Chip Q Single Cell Kit, 48 rxns",
              type: "CHIP",
            },
          ],
          name: "Chip",
          skus: [],
        },
        {
          details: [
            {
              id: "1000251",
              name: "Dual Index Kit TS Set A, 96 rxn",
              type: "RGT",
            },
            {
              id: "1000215",
              name: "Dual Index Kit TT Set A 96 rxns",
              type: "RGT",
            },
            {
              id: "1000250",
              name: "Dual Index Kit TN Set A, 96 rxn",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000474",
              name: "Chromium Fixed RNA Kit, Human Transcriptome, 4rxns x 1 BC",
              type: "RGT",
            },
            {
              id: "1000475",
              name: "Chromium Fixed RNA Kit, Human Transcriptome, 4rxns x 4 BC",
              type: "RGT",
            },
            {
              id: "1000476",
              name: "Chromium Fixed RNA Kit, Human Transcriptome, 4rxns x 16 BC",
              type: "RGT",
            },
            {
              id: "1000547",
              name: "Chromium Fixed RNA Kit, Human Transcriptome, 16rxns x 16 BC",
              type: "RGT",
            },
            {
              id: "1000495",
              name: "Chromium Fixed RNA Kit, Mouse Transcriptome, 4rxns x 1 BC",
              type: "RGT",
            },
            {
              id: "1000496",
              name: "Chromium Fixed RNA Kit, Mouse Transcriptome, 4rxns x 4 BC",
              type: "RGT",
            },
            {
              id: "1000497",
              name: "Chromium Fixed RNA Kit, Mouse Transcriptome, 4rxns x 16 BC",
              type: "RGT",
            },
            {
              id: "1000568",
              name: "Chromium Fixed RNA Kit, Mouse Transcriptome, 16rxns x 16 BC",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
        {
          details: [
            {
              id: "1000419",
              name: "Fixed RNA Feature Barcode Kit, 16 rxns",
              type: "RGT",
            },
            {
              id: "1000628",
              name: "Fixed RNA Feature Barcode Multiplexing Kit, 64 rxns",
              type: "RGT",
            },
          ],
          name: "Feature Barcoding Kit (optional)",
          skus: [],
        },
      ],
    },
  ],
};

export const epiAtac: ProductCardDetails = {
  name: "Epi ATAC",
  type: "assay",
  versions: [
    {
      compatibleProducts: [
        {
          display: "Chromium X",
          name: "Chromium X Series",
          version: "Chromium X",
          url: "/instruments/chromium-x",
        },
        {
          display: "Chromium iX",
          name: "Chromium X Series",
          version: "Chromium iX",
          url: "/instruments/chromium-family",
        },
      ],
      name: "",
      subgroups: [
        {
          details: [
            {
              id: "1000493",
              name: "Chromium Nuclei Isolation Kit, 16rxns",
              type: "RGT",
            },
          ],
          name: "Nuclei Isolation (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000162",
              name: "Chromium Next GEM Chip H Single Cell Kit, 16 rxns",
              type: "CHIP",
            },
            {
              id: "1000161",
              name: "Chromium Next GEM Chip H Single Cell Kit, 48 rxns",
              type: "CHIP",
            },
          ],
          name: "Chip",
          skus: [],
        },
        {
          details: [
            {
              id: "1000212",
              name: "Single Index Kit N Set A, 96 rxn",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000406",
              name: "Chromium Next GEM Single Cell ATAC Kit v2, 4 rxn",
              type: "RGT",
            },
            {
              id: "1000390",
              name: "Chromium Next GEM Single Cell ATAC Kit v2, 16 rxn",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
      ],
    },
  ],
};

export const epiMultiome: ProductCardDetails = {
  name: "Epi Multiome ATAC + Gene Expression",
  type: "assay",
  versions: [
    {
      compatibleProducts: [
        {
          display: "Chromium X",
          name: "Chromium X Series",
          version: "Chromium X",
          url: "/instruments/chromium-x",
        },
        {
          display: "Chromium iX",
          name: "Chromium X Series",
          version: "Chromium iX",
          url: "/instruments/chromium-family",
        },
      ],
      name: "",
      subgroups: [
        {
          details: [
            {
              id: "1000494",
              name: "Chromium Nuclei Isolation with RNase Inhibitor Kit, 16rxns",
              type: "RGT",
            },
          ],
          name: "Nuclei Isolation (optional)",
          skus: [],
        },
        {
          details: [
            {
              id: "1000230",
              name: "Chromium Next GEM Chip J Single Cell Kit, 16 rxns",
              type: "CHIP",
            },
            {
              id: "1000234",
              name: "Chromium Next GEM Chip J Single Cell Kit, 48 rxns",
              type: "CHIP",
            },
          ],
          name: "Chip",
          skus: [],
        },
        {
          details: [
            {
              id: "1000215",
              name: "Dual Index Kit TT Set A 96 rxns",
              type: "RGT",
            },
            {
              id: "1000212",
              name: "Single Index Kit N Set A, 96 rxn",
              type: "RGT",
            },
          ],
          name: "Index Kit",
          skus: [],
        },
        {
          details: [
            {
              id: "1000285",
              name: "Chromium Next GEM Single Cell Multiome ATAC + Gene Expression Reagent Bundle, 4 rxns",
              type: "RGT",
            },
            {
              id: "1000283",
              name: "Chromium Next GEM Single Cell Multiome ATAC + Gene Expression Reagent Bundle, 16 rxns",
              type: "RGT",
            },
          ],
          name: "Core Reagents",
          skus: [],
        },
      ],
    },
  ],
};

export const chromiumX: ProductCardDetails = {
  name: "Chromium X",
  type: "instrument",
  versions: [
    {
      compatibleProducts: [
        {
          display: "Flex Gene Expression",
          name: "Flex Gene Expression",
          url: "/products/flex-gene-expression",
        },
        {
          display: "Universal 3' Gene Expression",
          name: "Universal 3' Gene Expression",
          url: "/products/universal-three-prime-gene-expression",
        },
        {
          display: "Universal 5' Gene Expression",
          name: "Universal 5' Gene Expression",
          url: "/products/universal-five-prime-gene-expression",
        },
        {
          display: "Chromium Single Cell ATAC",
          name: "Chromium Single Cell ATAC",
          url: "/products/single-cell-atac",
        },
        {
          display: "Chromium Single Cell Multiome ATAC + Gene Expression",
          name: "Chromium Single Cell Multiome ATAC + Gene Expression",
          url: "/products/single-cell-multiome-atac-plus-gene-expression",
        },
      ],
      name: "",
      subgroups: [
        {
          details: [
            {
              id: "1000331",
              name: "Chromium X & Accessory Kit,12 Mo. Warranty",
              type: "INST",
            },
          ],
          name: "Instrument",
          skus: [],
        },
        {
          details: [
            {
              id: "1000333",
              name: "Chromium iX/X Assurance Plan 12 Mo.",
              type: "SVC",
            },
          ],
          name: "Service Plans",
          skus: [],
        },
        {
          details: [
            {
              id: "1000393",
              name: "Chromium X Chip Holder",
              type: "ACC",
            },
            {
              id: "1000394",
              name: "10x Magnetic Separator HT",
              type: "ACC",
            },
          ],
          name: "Accessories",
          skus: [],
        },
        {
          details: [
            {
              id: "1000330",
              name: "Chromium X Upgrade Package",
              type: "UPG",
            },
          ],
          name: "Upgrades",
          skus: [],
        },
        {
          details: [
            {
              id: "1000143",
              name: "Chromium Next GEM Training Reagents, Gel Beads and Chip Kits",
              type: "TRNG",
            },
            {
              id: "1000386",
              name: "Chromium Next GEM Single Cell HT Training Kit, 32 rxns",
              type: "TRNG",
            },
            {
              id: "1000745",
              name: "Chromium GEM-X Training Reagents, Gel Beads and Chip Kits",
              type: "TRNG",
            },
          ],
          name: "Training Kit (optional)",
          skus: [],
        },
      ],
    },
  ],
};

export const chromiumIx: ProductCardDetails = {
  name: "Chromium iX",
  type: "instrument",
  versions: [
    {
      compatibleProducts: [
        {
          display: "Flex Gene Expression",
          name: "Flex Gene Expression",
          url: "/products/flex-gene-expression",
        },
        {
          display: "Universal 3' Gene Expression",
          name: "Universal 3' Gene Expression",
          url: "/products/universal-three-prime-gene-expression",
        },
        {
          display: "Universal 5' Gene Expression",
          name: "Universal 5' Gene Expression",
          url: "/products/universal-five-prime-gene-expression",
        },
        {
          display: "Epi ATAC",
          name: "Epi ATAC",
          url: "/products/single-cell-atac",
        },
        {
          display: "Epi Multiome ATAC + Gene Expression",
          name: "Epi Multiome ATAC + Gene Expression",
          url: "/products/single-cell-multiome-atac-plus-gene-expression",
        },
      ],
      name: "",
      subgroups: [
        {
          details: [
            {
              id: "1000328",
              name: "Chromium iX & Accessory Kit, 12 Mo. Warranty",
              type: "INST",
            },
          ],
          name: "Instrument",
          skus: [],
        },
        {
          details: [
            {
              id: "1000333",
              name: "Chromium iX/X Assurance Plan 12 Mo.",
              type: "SVC",
            },
          ],
          name: "Service Plans",
          skus: [],
        },
        {
          details: [
            {
              id: "1000393",
              name: "Chromium X Chip Holder",
              type: "ACC",
            },
            {
              id: "1000394",
              name: "10x Magnetic Separator HT",
              type: "ACC",
            },
          ],
          name: "Accessories",
          skus: [],
        },
        {
          details: [
            {
              id: "1000330",
              name: "Chromium X Upgrade Package",
              type: "UPG",
            },
          ],
          name: "Upgrades",
          skus: [],
        },
        {
          details: [
            {
              id: "1000143",
              name: "Chromium Next GEM Training Reagents, Gel Beads and Chip Kits",
              type: "TRNG",
            },
            {
              id: "1000386",
              name: "Chromium Next GEM Single Cell HT Training Kit, 32 rxns",
              type: "TRNG",
            },
            {
              id: "1000745",
              name: "Chromium GEM-X Training Reagents, Gel Beads and Chip Kits",
              type: "TRNG",
            },
          ],
          name: "Training Kit (optional)",
          skus: [],
        },
      ],
    },
  ],
};

export const chromiumXo: ProductCardDetails = {
  name: "Chromium Xo",
  type: "instrument",
  versions: [
    {
      compatibleProducts: [
        {
          display: "Universal 3' Gene Expression",
          name: "Universal 3' Gene Expression",
          url: "/products/universal-three-prime-gene-expression",
        },
      ],
      name: "",
      subgroups: [
        {
          details: [
            {
              id: "1000812",
              name: "Chromium Xo & Accessory Kit, 12 Mo. Warranty",
              type: "INST",
            },
          ],
          name: "Instrument",
          skus: [],
        },
        {
          details: [
            {
              id: "1000814",
              name: "Chromium Xo - 12 month Assurance Plan",
              type: "SVC",
            },
          ],
          name: "Service Plans",
          skus: [],
        },
        {
          details: [
            {
              id: "1000813",
              name: "Chromium Xo to X Upgrade",
              type: "UPG",
            },
          ],
          name: "Upgrades",
          skus: [],
        },
        {
          details: [
            {
              id: "1000745",
              name: "Chromium GEM-X Training Reagents, Gel Beads and Chip Kits",
              type: "TRNG",
            },
          ],
          name: "Training Kit (optional)",
          skus: [],
        },
      ],
    },
  ],
};
