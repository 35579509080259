import { type ComponentProps, Fragment } from "react";
import { v4 as uuidv4 } from "uuid";

import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";
import type { default as SectionBulletedListComponent } from "./BulletedList";
import type { default as SectionButtonComponent } from "./Button";
import type { default as SectionCarouselComponent } from "./Carousel";
import type { default as SectionGifComponent } from "./Gif";
import type { default as SectionImageComponent } from "./Image";
import type { default as SectionLinksComponent } from "./Links";
import type { default as SectionOrderedListComponent } from "./OrderedList";
import type { default as SectionParagraphsComponent } from "./Paragraphs";
import type { default as SectionTextComponent } from "./Text";
import type { default as SectionTitleComponent } from "./Title";
import type { default as SectionVideosComponent } from "./Videos";
import { getContent } from "./utils";

export type SectionContent =
  | typeof SectionBulletedListComponent
  | typeof SectionButtonComponent
  | typeof SectionCarouselComponent
  | typeof SectionGifComponent
  | typeof SectionImageComponent
  | typeof SectionLinksComponent
  | typeof SectionOrderedListComponent
  | typeof SectionParagraphsComponent
  | typeof SectionTextComponent
  | typeof SectionTitleComponent
  | typeof SectionVideosComponent;

export type ContentProps = ComponentProps<SectionContent> & {
  backgroundColor?: string;
  includeInNav?: boolean;
  navTitle?: string;
  type?: string;
};

interface Props {
  container: ContainerProps;
  content: ContentProps[];
}

const Section = ({ content, container }: Props) => {
  if (container) {
    container.maxWidth = container.maxWidth || "1200px";
  }
  return (
    <Container {...container}>
      {content.map((item) => {
        return <Fragment key={uuidv4()}>{getContent(item)}</Fragment>;
      })}
    </Container>
  );
};

export default Section;
