import { css } from "@emotion/react";
import type { IconSource } from "@10xdev/types/models/IconSource";
import type { Props as Header } from "../../ProductPage/SectionHeader";

import Dataset from "../../ProductPage/cards/dataset";
import SectionHeader from "../../ProductPage/SectionHeader";
import Grid from "../../Grid";
import Repeater from "../../ProductPage/Repeater";

interface Props {
  header?: Header;
  datasets: {
    title?: string;
    cta?: {
      label?: string;
      icon?: IconSource;
      url?: string;
    };
  }[];
}

const ProductPageDatasets = ({ header, datasets }: Props) => {
  return (
    <Grid
      css={css`
        max-width: 1200px;
        width: 100%;
      `}
      gap={"3.5rem"}
      padding={{ base: "0 1rem 2rem", tabletPortrait: "0 1rem 4.5rem" }}
    >
      {header ? <SectionHeader {...header} /> : null}
      {datasets.length ? <Repeater card={Dataset} list={datasets} /> : null}
    </Grid>
  );
};

export default ProductPageDatasets;
