import type { LinkItem } from "@10xdev/types/models/LinkItem";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Heading from "../Heading";
import Image from "../Image";
import Text from "../Text";
import type { Services } from "./types";

interface Props {
  description?: string;
  links?: {
    list?: Array<LinkItem>;
    title?: string;
  };
  logo?: string;
  services?: Services;
}

const SignupContent: FunctionComponent<Props> = ({
  description,
  services,
  links,
  logo,
}) => {
  const {
    serviceList,
    description: serviceDescription,
    title: serviceTitle,
  } = services || {};
  const { list, title: linksTitle } = links || {};

  return (
    <>
      {description ? (
        <Text
          as={"div"}
          color={"midgray"}
          css={css`
            margin-bottom: 3rem;
          `}
          size={"medium"}
        >
          {description}
        </Text>
      ) : null}

      <div
        css={css`
          margin-bottom: 6rem;
        `}
      >
        {serviceTitle ? (
          <Heading
            as={"h4"}
            css={css`
              margin-bottom: 0.5rem;
            `}
            size={"large"}
            weight={"semibold"}
          >
            {serviceTitle}
          </Heading>
        ) : null}

        {serviceDescription ? (
          <Text as={"p"} color={"midgray"} size={"medium"}>
            {serviceDescription}
          </Text>
        ) : null}

        {serviceList?.length ? (
          <ul
            css={css`
              list-style: none;
              margin-bottom: 3rem;
              padding: 0;
            `}
          >
            {serviceList.map((service) => {
              const { label, url } = service;
              return (
                <Text
                  as={"li"}
                  color={"blue"}
                  css={css`
                    margin-bottom: 0.5rem;
                  `}
                  key={url}
                  size={"medium"}
                  weight={"medium"}
                >
                  {url ? <Anchor href={url}>{label}</Anchor> : null}
                </Text>
              );
            })}
          </ul>
        ) : null}

        {linksTitle ? (
          <Heading
            as={"h4"}
            css={css`
              margin-bottom: 0.5rem;
            `}
            size={"large"}
            weight={"semibold"}
          >
            {linksTitle}
          </Heading>
        ) : null}

        {list?.length
          ? list.map((link) => {
              const { label, url } = link;
              return (
                <Text
                  as={"div"}
                  color={"blue"}
                  css={css`
                    margin-bottom: 0.5rem;
                  `}
                  key={url}
                  size={"medium"}
                  weight={"medium"}
                >
                  {url ? <Anchor href={url}>{label}</Anchor> : null}
                </Text>
              );
            })
          : null}
      </div>

      {logo ? (
        <div className={"Image"}>
          <Image alt={"10x Genomics"} src={logo} width={"40%"} />
        </div>
      ) : null}
    </>
  );
};

export default SignupContent;
