import {
  borderStandard,
  mediaPhoneOnly,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Grid from "../../Grid";
import type { IconSource } from "@10xdev/types/models/IconSource";
import Image from "../../Image";
import OverviewSection from "./OverviewSection";

type Link = {
  icon: IconSource;
  label: string;
  url: string;
};

type Image = {
  alt: string;
  src: string;
  srcSmall?: string;
};

export type Section = {
  description?: string[];
  links?: Link[];
  list?: string[];
  title: string;
  cta?: {
    link: Link;
    description: string;
    image: Image;
  };
};

interface Props {
  image?: Image;
  sections: Section[];
}

const PlatformOverview = ({ image, sections }: Props) => {
  return (
    <Grid
      css={css`
        max-width: 1200px;
        padding: 2.5rem 1rem;
        @media (min-width: ${mediaTabletLandscape}) {
          padding: 4rem 1rem 4.5rem;
        }
      `}
      gridTemplateColumns={{ base: "1fr", tabletPortrait: "237px 1fr" }}
    >
      {image && image.srcSmall ? (
        <Image
          alt={image.alt}
          css={css`
            display: block;
            @media (min-width: ${mediaPhoneOnly}) {
              display: none;
            }
          `}
          src={image.srcSmall}
        />
      ) : null}
      {image ? (
        <Image
          alt={image.alt}
          css={css`
            display: none;
            @media (min-width: ${mediaPhoneOnly}) {
              display: block;
            }
          `}
          src={image.src}
        />
      ) : null}

      <Grid alignContent={"flex-start"}>
        {sections.map((section) => (
          <OverviewSection
            css={css`
              border-bottom: ${borderStandard};
              padding: 1.5rem 0;
              &:last-of-type {
                border-bottom: none;
                padding-bottom: 0;
              }
            `}
            key={section.title}
            section={section}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default PlatformOverview;
