import { layoutWidth1200, mediaPhoneOnly } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import SubNavCard from "../../Cards/SubNavCard";
import type { IconSource } from "@10xdev/types/models/IconSource";
import ThematicBreak from "../../ThematicBreak";

interface Props {
  links: Array<{
    active: boolean;
    description: string;
    href: string;
    iconSource: IconSource;
    target?: "_blank";
    title: string;
  }>;
}

const NewsSubNav: FunctionComponent<Props> = ({ links }) => {
  return (
    <nav className={"NewSubNav"}>
      <ul
        css={css`
          display: flex;
          flex-direction: row;
          gap: 32px;
          margin-bottom: 64px;
          margin-top: 64px;
          max-width: ${layoutWidth1200};
          padding-left: 0;

          @media (max-width: ${mediaPhoneOnly}) {
            flex-direction: column;
            height: 700px;
            justify-content: space-between;
          }
        `}
      >
        {links.map((link) => {
          return (
            <li
              css={css`
                list-style: none;
                max-width: 100%;
                width: 379px;
              `}
              key={link.title}
            >
              <SubNavCard {...link} />
            </li>
          );
        })}
      </ul>
      <ThematicBreak />
    </nav>
  );
};

export default NewsSubNav;
