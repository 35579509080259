import {
  borderStandard,
  colorBlueDark,
  layoutWidth1200,
  mediaPhoneOnly,
  mediaTabletLandscape,
  sizeLarge,
  sizeSmall,
  sizeXxlarge,
  sizeXxsmall,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import type { IconSource } from "@10xdev/types/models/IconSource";
import Image from "../../Image";
import Paragraph from "../../Paragraph";
import Text from "../../Text";

interface Download {
  icon: IconSource;
  label: string;
  url: string;
}

interface Extension {
  description: string;
  downloads?: Download[];
  features: any[];
  icon?: string;
  illustration?: unknown;
  links?: any[];
  title: string;
  type?: string;
}

interface Props {
  extensions?: Extension[];
  title?: string;
}

const Extensions: FunctionComponent<Props> = ({ extensions, title }) => {
  return (
    <div
      className={"Extensions"}
      css={css`
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem;

        @media (max-width: ${mediaPhoneOnly}) {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      `}
    >
      <div
        css={css`
          @media (max-width: ${mediaPhoneOnly}) {
            margin-bottom: 2rem;
          }
          margin-bottom: 4rem;
        `}
      >
        <Heading
          as={"h3"}
          css={css`
            border-bottom: ${borderStandard};
            padding-bottom: ${sizeLarge};
          `}
          responsive={true}
          size={"xlarge"}
        >
          {title}
        </Heading>
      </div>

      {extensions && extensions.length
        ? extensions.map((extension) => {
            const { description, features, icon, links, title, type } =
              extension;

            return (
              <div
                css={css`
                  display: flex;
                  margin-bottom: 4rem;
                  @media (max-width: ${mediaTabletLandscape}) {
                    display: block;
                  }
                  @media (max-width: ${mediaPhoneOnly}) {
                    margin-bottom: 2rem;
                  }
                `}
                key={title}
              >
                <div
                  css={css`
                    transform: scale(1.3) translate(-35%, 4rem);
                    width: 30%;
                    @media (max-width: ${mediaTabletLandscape}) {
                      transform: scale(0.75) translate(0, 0);
                      width: 100%;
                    }
                    @media (max-width: ${mediaPhoneOnly}) {
                      transform: translate(0, 0);
                      width: 100%;
                    }
                  `}
                >
                  {icon && <Image alt={title} src={icon} />}
                </div>

                <div
                  css={css`
                    width: 65%;

                    @media (max-width: ${mediaTabletLandscape}) {
                      display: block;
                      width: 100%;
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;

                      @media (max-width: ${mediaPhoneOnly}) {
                        display: block;
                      }
                    `}
                  >
                    <div
                      css={css`
                        padding-right: 3.75rem;
                        width: 55%;

                        @media (max-width: ${mediaTabletLandscape}) {
                          padding-right: 1rem;
                          width: 100%;
                        }

                        @media (max-width: ${mediaPhoneOnly}) {
                          padding-right: 0;
                          width: 100%;
                        }
                      `}
                    >
                      {type ? (
                        <Text
                          as={"p"}
                          color={"blue"}
                          css={css`
                            margin-bottom: ${sizeSmall};
                          `}
                          responsive={true}
                          size={"medium"}
                          weight={"medium"}
                        >
                          {type}
                        </Text>
                      ) : null}
                      <Heading
                        as={"h4"}
                        css={css`
                          line-height: 1;
                        `}
                        size={"xxlarge"}
                      >
                        {title}
                      </Heading>
                      <div
                        css={css`
                          margin-bottom: 4rem;

                          @media (max-width: ${mediaPhoneOnly}) {
                            margin-bottom: 0;
                          }
                        `}
                      >
                        <Paragraph
                          color={"midgray"}
                          responsive={true}
                          size={"medium"}
                        >
                          {description}
                        </Paragraph>
                      </div>
                      <div
                        css={css`
                          @media (max-width: ${mediaPhoneOnly}) {
                            display: none;
                          }
                        `}
                      >
                        {links && links.length
                          ? links.map((link) => {
                              const { label, type, url } = link;
                              return (
                                <Text
                                  as={"div"}
                                  color={"blue"}
                                  css={css`
                                    margin-bottom: 0.5rem;
                                  `}
                                  key={url}
                                  size={"small"}
                                  weight={"semibold"}
                                >
                                  {url ? (
                                    <Anchor
                                      aria-label={`${label} for ${title}`}
                                      color={"inherit"}
                                      hoverColor={colorBlueDark}
                                      href={url}
                                    >
                                      {label}
                                      {type ? (
                                        <Icon
                                          size={"15px"}
                                          source={type}
                                          xPos={"16px"}
                                          yPos={"2px"}
                                        />
                                      ) : null}
                                    </Anchor>
                                  ) : null}
                                </Text>
                              );
                            })
                          : null}
                      </div>
                    </div>

                    <ul
                      css={css`
                        margin-bottom: 0;
                        padding: ${sizeXxlarge} 0 0;
                        width: 40%;

                        @media (max-width: ${mediaTabletLandscape}) {
                          padding-bottom: ${sizeXxlarge};
                          padding-top: ${sizeXxsmall};
                          width: 100%;
                        }
                      `}
                    >
                      {features && features.length
                        ? features.slice(0, 3).map((feature) => {
                            return (
                              <li
                                css={css`
                                  background: url("https://cdn.10xgenomics.com/image/upload/v1588968667/icons/checkmark.svg")
                                    no-repeat;
                                  list-style: none;
                                  margin-bottom: ${sizeLarge};
                                  padding-left: ${sizeXxlarge};
                                  padding-top: ${sizeXxsmall};

                                  :last-of-type {
                                    margin-bottom: 0;
                                  }
                                `}
                                key={feature}
                              >
                                <Text
                                  as={"span"}
                                  color={"midgray"}
                                  size={"xsmall"}
                                >
                                  {feature}
                                </Text>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                  <div
                    css={css`
                      display: none;

                      @media (max-width: ${mediaPhoneOnly}) {
                        display: block;
                      }
                    `}
                  >
                    {links && links.length
                      ? links.map((link) => {
                          const { label, type, url } = link;
                          return (
                            <Text
                              as={"div"}
                              color={"blue"}
                              css={css`
                                margin-bottom: 1rem;
                              `}
                              key={url}
                              size={"small"}
                              weight={"semibold"}
                            >
                              <Anchor
                                color={"inherit"}
                                hoverColor={colorBlueDark}
                                href={url}
                              >
                                {label}
                                {type ? (
                                  <Icon
                                    size={"13px"}
                                    source={type}
                                    xPos={"16px"}
                                    yPos={"1px"}
                                  />
                                ) : null}
                              </Anchor>
                            </Text>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default Extensions;
