import {
  colorBlueDark,
  colorGrayLightest,
  colorWhite,
  layoutWidth1200,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import type { IconSource } from "@10xdev/types/models/IconSource";

import Anchor from "../../Anchor";
import ResourceCard from "../../Cards/ResourceCard";
import DocumentCard from "../../DocumentCard";
import Heading from "../../Heading";
import Icon from "../../Icon";
import PublicationCard from "../../PublicationCard";
import Slash from "../../Slash";
import Text from "../../Text";
import VideoCard from "../../VideoCard";

interface Props {
  cardColor: "white" | "gray";
  equalColumns: boolean;
  eyebrow?: string;
  heading: string;
  headingSize?: "xxxlarge" | "xxlarge";
  link: {
    label: string;
    type: IconSource;
    url: string;
  };
  overview?: string;
  overviewSize: "large" | "medium";
  resourceLimit: number;
  resources: Array<Record<string, any>>;
  secondaryLink?: {
    label: string;
    type: IconSource;
    url: string;
  };
  slash: boolean;
}

const colors = {
  ["gray"]: colorGrayLightest,
  ["white"]: colorWhite,
};

const columnCss = css`
  box-sizing: border-box;
  height: 100%;
`;

const ResourceList: FunctionComponent<Props> = ({
  cardColor = "white",
  equalColumns = false,
  eyebrow,
  heading,
  headingSize = "xxxlarge",
  link,
  overview,
  overviewSize = "large",
  resources,
  resourceLimit = 3,
  secondaryLink,
  slash,
}) => {
  const getCard = (item: Record<string, any>) => {
    const { citation, description, thumbnail, title, type, url, wistiaID } =
      item;

    switch (type) {
      case "document":
        return (
          <DocumentCard
            background={colors[cardColor]}
            hover={true}
            info={citation}
            key={title}
            title={title}
            url={url}
          />
        );
      case "publication":
        return (
          <PublicationCard
            background={colors[cardColor]}
            hover={true}
            info={citation}
            key={title}
            title={title}
            url={url}
          />
        );
      case "video":
        return (
          <VideoCard
            background={colors[cardColor]}
            hover={true}
            info={citation}
            key={title}
            thumbnail={thumbnail}
            title={title}
            wistiaId={wistiaID}
          />
        );
      case "resource":
        return (
          <ResourceCard
            background={colors[cardColor]}
            hover={true}
            info={description}
            key={title}
            title={title}
            url={url}
          />
        );
    }
  };

  return (
    <div
      className={"ResourceList"}
      css={css`
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem;
        width: 100%;
      `}
    >
      <div
        css={css`
          box-sizing: border-box;
          display: flex;
          width: 100%;
          height: 100%;
          flex-wrap: wrap;

          @media (max-width: ${mediaTabletLandscape}) {
            flex-direction: column;
            flex-wrap: nowrap;
          }
        `}
      >
        <div
          css={css`
            ${columnCss};

            flex: ${equalColumns ? 4 : 3};
            margin-right: 10%;
            margin-bottom: 2rem;

            @media (max-width: ${mediaTabletLandscape}) {
              margin-bottom: 3rem;
            }
          `}
        >
          <Slash slash={slash}>
            {eyebrow ? (
              <Text
                as={"div"}
                color={"blue"}
                css={css`
                  margin-bottom: 1.5rem;
                `}
                responsive={true}
                size={"large"}
                weight={"semibold"}
              >
                {eyebrow}
              </Text>
            ) : null}
            {heading ? (
              <Heading
                as={"h3"}
                css={css`
                  margin-bottom: 3rem;
                `}
                responsive={true}
                size={headingSize}
              >
                {heading}
              </Heading>
            ) : null}
          </Slash>

          <Text
            as={"div"}
            color={"midgray"}
            css={css`
              margin-bottom: ${link ? "3rem" : "0px"};
            `}
            responsive={true}
            size={overviewSize}
          >
            {overview}
          </Text>

          {link ? (
            <Text as={"span"} color={"blue"} size={"small"} weight={"semibold"}>
              <Anchor
                color={"inherit"}
                hoverColor={colorBlueDark}
                href={link?.url}
              >
                {link?.label}
                {link?.type ? (
                  <Icon
                    size={"12px"}
                    source={link.type}
                    xPos={"16px"}
                    yPos={"1px"}
                  />
                ) : null}
              </Anchor>
            </Text>
          ) : null}
        </div>

        {resources ? (
          <div
            css={css`
              ${columnCss};

              flex: ${equalColumns ? 5 : 6};
              overflow: visibile;

              @media (max-width: ${mediaTabletLandscape}) {
                overflow-x: scroll;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;

                @media (max-width: ${mediaTabletLandscape}) {
                  flex-direction: row;
                  margin-bottom: 2rem;
                }
              `}
            >
              {resources.slice(0, resourceLimit).map((resource) => {
                return getCard(resource);
              })}
            </div>

            {secondaryLink?.url ? (
              <div
                css={css`
                  align-items: center;
                  display: flex;
                  flex-direction: column;
                  width: 100%;

                  @media (max-width: ${mediaTabletLandscape}) {
                    display: none;
                  }
                `}
              >
                <Text
                  as={"span"}
                  color={"blue"}
                  key={secondaryLink?.url || link?.url}
                  size={"small"}
                  weight={"medium"}
                >
                  <Anchor
                    color={"inherit"}
                    hoverColor={colorBlueDark}
                    href={secondaryLink?.url || link?.url}
                  >
                    {secondaryLink?.label || "View more"}
                    {secondaryLink?.type ? (
                      <Icon
                        size={"12px"}
                        source={secondaryLink?.type}
                        xPos={"16px"}
                        yPos={"1px"}
                      />
                    ) : null}
                  </Anchor>
                </Text>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ResourceList;
