import type { Product } from "@10xdev/types/models/Product";
import type { ProductVariation } from "@10xdev/types/models/ProductVariation";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../../Heading";
import Text from "../../Text";

interface Props {
  assays: {
    mode?: string;
    productVariations?: ProductVariation[];
    products?: Product[];
  }[];
  instrument: string;
}

const Compatibility: FunctionComponent<Props> = ({ assays, instrument }) => {
  return (
    <>
      {assays.map((assay, index) => {
        const { mode, productVariations = [], products = [] } = assay;
        const allAssays = [...productVariations, ...products];

        return (
          <div
            css={css`
              :first-of-type {
                margin-bottom: 2rem;
              }
            `}
            key={`${instrument}-${index}`}
          >
            {mode ? (
              <Heading
                as={"h5"}
                css={css`
                  margin-bottom: 8px;
                `}
                responsive={true}
                size={"xsmall"}
                weight={"semibold"}
              >
                {mode}
              </Heading>
            ) : null}
            <ul
              css={css`
                list-style: none;
                margin: 0;
                padding: 0;
              `}
            >
              {allAssays.map((assay) => {
                return (
                  <Text
                    as={"li"}
                    color={"base"}
                    css={css`
                      margin-bottom: 4px;
                    `}
                    key={assay.name}
                    size={"small"}
                  >
                    {assay.name}
                  </Text>
                );
              })}
            </ul>
          </div>
        );
      })}
    </>
  );
};

export default Compatibility;
