import {
  borderStandard,
  layoutWidth800,
  layoutWidth1200,
  mediaDesktop,
} from "@10xdev/design-tokens";
import type { Card } from "@10xdev/types/models/Card";
import type { LinkItem } from "@10xdev/types/models/LinkItem";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import { SPACINGS } from "../../../constants";
import DisplayCard from "../../Cards/DisplayCard";
import OverviewCard from "../../Cards/OverviewCard";
import Heading from "../../Heading";
import SectionHeading from "../../SectionHeading";

interface Props {
  borders: boolean;
  cardList?: Array<any>;
  label: string;
  sectionHeading: {
    description?: string;
    eyebrow?: string;
    heading: string;
    headingLink?: LinkItem;
    headingSize: "xxxlarge" | "xxlarge";
    slash: boolean;
  };
  verticalSpacing?: "none" | "small" | "medium" | "large";
}

const Panels: FunctionComponent<Props> = ({
  borders = true,
  cardList,
  sectionHeading,
  label,
  verticalSpacing = "medium",
}) => {
  const getCard = (item: Card) => {
    switch (item.type) {
      case "overviewCard":
        return (
          <OverviewCard
            description={item.description}
            image={{
              alt: item.image?.alt,
              url: item.image?.url,
            }}
            key={item.title || item.description}
            linkList={item.linkList}
            list={item.list}
            title={item.title}
          />
        );
      case "displayCard":
        return (
          <DisplayCard
            alignLayout={item.alignLayout}
            alignText={item.alignText}
            description={item.description}
            image={item.image}
            key={item.title || item.description}
            link={item.link}
            reverse={item.reverse}
            title={item.title}
            titleSize={item.titleSize}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={"Panels"}
      css={css`
        box-sizing: border-box;
        max-width: ${layoutWidth1200};
        padding: ${verticalSpacing && SPACINGS[verticalSpacing]} 1rem;
        width: 100%;

        @media (min-width: ${layoutWidth800}) {
          padding-left: 2rem;
          padding-right: 2rem;
        }

        @media (min-width: ${mediaDesktop}) {
          padding-left: 0;
          padding-right: 0;
        }
      `}
    >
      {sectionHeading?.heading?.length ? (
        <SectionHeading
          eyebrow={sectionHeading.eyebrow}
          heading={sectionHeading.heading}
          headingSize={sectionHeading.headingSize}
          marginBottom={sectionHeading.heading ? "3rem" : "0px"}
          slash={sectionHeading.slash}
        />
      ) : null}

      {label ? (
        <Heading
          as={"h4"}
          css={css`
            margin-bottom: 4rem;
            text-align: center;
          `}
          responsive={true}
          size={"xlarge"}
        >
          {label}
        </Heading>
      ) : null}

      {cardList?.length ? (
        <ul
          css={css`
            list-style: none;
            margin: 0;
            padding: 0;

            > li:not(:last-of-type) {
              border-bottom: ${borderStandard};
              margin-bottom: 3rem;
              padding-bottom: 3rem;
            }

            @media (min-width: ${layoutWidth800}) {
              box-sizing: border-box;
              display: flex;
              flex-wrap: nowrap;
              justify-content: space-between;

              > li {
                flex: 1;
                padding-left: 4rem;
                padding-right: 4rem;
              }

              > li:not(:last-of-type) {
                border-bottom: none;
                border-right: ${borders ? borderStandard : "none"};
                margin-bottom: 0;
                padding-bottom: 0;
              }

              > li:first-of-type {
                padding-left: 0;
              }

              > li:last-of-type {
                padding-right: 0;
              }
            }
          `}
        >
          {cardList.slice(0, 3).map((card) => {
            return getCard(card);
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default Panels;
