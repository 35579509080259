import type { LinkItem } from "@10xdev/types/models/LinkItem";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  linkList: Array<LinkItem>;
  title: string;
}

const LinkList: FunctionComponent<Props> = ({ title, linkList }) => {
  return (
    <div>
      {title ? (
        <Heading
          as={"h4"}
          css={css`
            margin-bottom: 1rem;
          `}
          size={"medium"}
        >
          {title}
        </Heading>
      ) : null}

      {linkList?.length
        ? linkList.map((link) => {
            const { label, type, url } = link;

            return (
              <>
                {url ? (
                  <Text
                    as={"div"}
                    color={"blue"}
                    css={css`
                      margin-bottom: 0.5rem;
                    `}
                    key={url}
                    size={"small"}
                    weight={"semibold"}
                  >
                    <Anchor href={url} target={"_self"}>
                      {label}
                      {type ? (
                        <Icon
                          css={{ marginLeft: ".5rem" }}
                          size={"11px"}
                          source={type}
                          yPos={"1px"}
                        />
                      ) : null}
                    </Anchor>
                  </Text>
                ) : null}
              </>
            );
          })
        : null}
    </div>
  );
};

LinkList.defaultProps = {};

export default LinkList;
