import {
  borderStandard,
  colorBlueDark,
  layoutWidth1200,
  mediaTabletLandscape,
  sizeXlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import type { IconSource } from "@10xdev/types/models/IconSource";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  items?: Array<{
    label?: string;
    type?: IconSource;
    url: string;
  }>;
  title?: string;
}

const Resources: FunctionComponent<Props> = ({ items, title }) => {
  return items && items.length ? (
    <div
      className={"Resources"}
      css={css`
        align-items: center;
        display: flex;
        height: 86px;
        justify-content: flex-start;
        padding: 1.5rem 1rem;
        max-width: ${layoutWidth1200};
        width: 100%;

        @media (max-width: ${mediaTabletLandscape}) {
          -webkit-overflow-scrolling: touch;
          border-bottom: ${borderStandard};
          overflow-x: auto;
          overflow-y: hidden;
          padding: 0 1rem;
        }
      `}
    >
      {title ? (
        <Heading
          as={"h3"}
          color={"gray"}
          css={css`
            margin: 0 ${sizeXlarge} 0 0;
          `}
          size={"small"}
          weight={"regular"}
        >
          {title}
        </Heading>
      ) : null}

      <ul
        css={css`
          align-items: center;
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {items.map((resource, index) => {
          const { label, type, url } = resource;

          return (
            <li
              css={css`
                border-right: ${borderStandard};
                margin: 0 ${sizeXlarge} 0 0;
                padding: 0 ${sizeXlarge} 0 0;
                white-space: nowrap;

                :last-of-type {
                  border: none;
                  margin: 0;
                }
              `}
              key={label}
            >
              <Text
                as={"span"}
                color={"blue"}
                size={"small"}
                weight={"semibold"}
              >
                <Anchor
                  /* Class name is for Google tracking, please do not change. */
                  className={"quick-link"}
                  color={"inherit"}
                  /* This ID is for Google tracking. Do not change plz. */
                  hoverColor={colorBlueDark}
                  href={url}
                  id={`quick-link-${index + 1}`}
                >
                  {label}
                  {type ? (
                    <Icon
                      css={{ marginRight: "1rem" }}
                      size={"14px"}
                      source={type}
                      xPos={"16px"}
                      yPos={"2px"}
                    />
                  ) : null}
                </Anchor>
              </Text>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};

export default Resources;
