import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Grid from "../../../Grid";
import Image from "../../../Image";
import Text from "../../../Text";

interface Props {
  description: string;
  eyebrow: string;
  image: {
    alt: string;
    src: string;
  };
  title: string;
  width?: string;
}

const ImageCard = ({
  description,
  eyebrow,
  image,
  title,
  width = "500px",
}: Props) => {
  return (
    <Grid
      css={css`
        box-sizing: border-box;
        flex: 1 0 auto;
        gap: 1rem;
        overflow: hidden;
        @media (min-width: ${mediaTabletLandscape}) {
          max-width: ${width};
        }
      `}
      gap={"1rem"}
    >
      <Grid gap={".5rem"}>
        <Text as={"h4"} color={"blue"} size={"medium"} weight={"semibold"}>
          {eyebrow}
        </Text>
        <Text as={"p"} size={"large"} weight={"semibold"}>
          {title}
        </Text>
        <Text as={"p"} size={"small"} weight={"regular"}>
          {description}
        </Text>
      </Grid>
      <Image alt={image.alt} src={image.src} />
    </Grid>
  );
};

export default ImageCard;
