import { mediaDesktop } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Carousel from "../../Carousel";
import Grid from "../../Grid";
import type { Props as Header } from "../../ProductPage/SectionHeader";
import SectionHeader from "../../ProductPage/SectionHeader";
import Video from "../../ProductPage/cards/video";

interface Props {
  header?: Header;
  videos: {
    title: string;
    wistiaID: string;
  }[];
}

const carouselSettings = {
  itemHeight: 282,
  aspectRatio: 360 / 282,
  gap: 16,
  itemsPerScroll: 1,
};

const ProductPageVideos = ({ header, videos }: Props) => {
  return (
    <Grid
      css={css`
        box-sizing: border-box;
        padding: 0 1rem 2rem;
        width: 100%;

        @media (min-width: ${mediaDesktop}) {
          padding: 0 1rem 4.5rem 2rem;
        }

        @media (min-width: 1264px) {
          padding: 0 1rem 4.5rem calc((100vw - 1200px) / 2);
        }
      `}
      gap={"1.5rem"}
    >
      {header ? <SectionHeader {...header} /> : null}
      {videos.length ? (
        <Carousel {...carouselSettings}>
          {videos.map((video) => (
            <Video
              key={video.wistiaID}
              wistiaID={video.wistiaID}
              title={video.title}
            />
          ))}
        </Carousel>
      ) : null}
    </Grid>
  );
};

export default ProductPageVideos;
