import { mediaDesktop, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { debounce } from "lodash-es";
import type { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";

import Button from "../../Button";
import Icon from "../../Icon";

interface Props {
  active?: string;
  aspectRatio: number;
  children: ReactNode;
  gap?: number;
  itemHeight: number;
  itemsPerScroll?: number;
}

const Carousel = ({
  active,
  aspectRatio = 1.1,
  children,
  gap = 16,
  itemsPerScroll = 3,
  itemHeight = 312,
}: Props) => {
  const itemWidth = Math.round(itemHeight * aspectRatio);
  const viewWidth = (itemWidth + gap) * itemsPerScroll;
  const carouselRef = useRef<HTMLDivElement>(null);

  const [offSet, setOffSet] = useState(0);

  const handlePreviousClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scroll({
        left: carouselRef.current.scrollLeft - viewWidth,
        behavior: "smooth",
      });
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scroll({
        left: carouselRef.current.scrollLeft + viewWidth,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = 0;
    }
  }, [active]);

  const handleScrollDebounced = debounce((event) => {
    setOffSet((event.target as HTMLDivElement).scrollLeft);
  }, 100);

  return (
    <div
      className={"Carousel"}
      css={css`
        overflow-x: hidden;
        position: relative;
        padding-left: 0;

        @media (min-width: ${mediaTabletLandscape}) {
          padding-left: 1rem;
        }

        @media (min-width: ${mediaDesktop}) {
          padding-left: 0;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          overflow-x: scroll;
          width: 100%;
          gap: ${gap}px;

          @media (min-width: ${mediaTabletLandscape}) {
            flex-direction: row;
            min-height: ${itemHeight}px;
            overflow: scroll;
            overscroll-behavior-x: contain;
            padding-bottom: 0;

            > :last-child {
              margin-right: 120px;
            }
          }
        `}
        onScroll={handleScrollDebounced}
        ref={carouselRef}
      >
        {children}
      </div>
      <div
        css={css`
          align-items: center;
          background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0) 0%,
            #ffffff 86.24%
          );
          display: none;
          height: 100%;
          justify-content: start;
          position: absolute;
          top: 0;
          width: 120px;

          @media (min-width: ${mediaTabletLandscape}) {
            display: flex;
            opacity: ${offSet > 0 ? "1" : "0"};
            transition: opacity 0.4s cubic-bezier(0.77, 0.17, 0.21, 0.81);
          }
        `}
      >
        <Button
          background={"gray"}
          color={"white"}
          css={css`
            border-radius: 50%;
            height: 4rem;
            margin-left: 1rem;
            width: 4rem;
          `}
          onClick={handlePreviousClick}
        >
          <Icon size={"22px"} source={"nav-left"} xPos={"-1px"} />
        </Button>
      </div>
      <div
        css={css`
          align-items: center;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            #ffffff 86.24%
          );
          display: none;
          height: 100%;
          justify-content: end;
          left: 100%;
          position: absolute;
          top: 0;
          transform: translateX(-100%);
          width: 120px;

          @media (min-width: ${mediaTabletLandscape}) {
            display: flex;
          }
        `}
      >
        <Button
          background={"gray"}
          color={"white"}
          css={css`
            border-radius: 50%;
            display: none;
            height: 4rem;
            margin-right: 1rem;
            width: 4rem;

            @media (min-width: ${mediaTabletLandscape}) {
              display: flex;
            }

            &:disabled > svg {
              color: white;
            }
          `}
          onClick={handleNextClick}
          disabled={
            offSet + (carouselRef.current?.clientWidth || 0) >=
            (carouselRef.current?.scrollWidth || 0)
          }
        >
          <Icon size={"22px"} source={"nav-right"} xPos={"1px"} />
        </Button>
      </div>
    </div>
  );
};

export default Carousel;
