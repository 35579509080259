import { css } from "@emotion/react";

import type { Link, LinkSize } from "./types";
import { buttonCss } from "../../Button";

import Text from "../../Text";
import Anchor from "../../Anchor";
import Icon from "../../Icon";
import Flex from "../../Flex";
import { mediaTabletPortrait } from "@10xdev/design-tokens";

const ctaCss = css`
  align-content: center;
  align-self: center;
  display: flex;
  justify-content: center;
  padding: 8px 1rem;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
`;

const Link = ({
  label,
  labelSmall,
  size = "small",
  url,
  icon = "nav-right",
  variant = "text",
}: Link) => {
  const getIconSize = (size: LinkSize) => {
    const sizes: Record<LinkSize, string> = {
      medium: "16px",
      small: "12px",
      xsmall: "10px",
    };
    return sizes[size];
  };

  const buttonStyle = variant === "button" ? [buttonCss({}), ctaCss] : "";

  return (
    <Anchor href={url} css={buttonStyle}>
      <Flex alignItems={"center"} gap={".75rem"}>
        <Text
          as={"span"}
          css={css`
            @media (max-width: ${mediaTabletPortrait}) {
              display: ${labelSmall ? "none" : "block"};
            }
          `}
          size={size}
          color={"inherit"}
          weight={"medium"}
        >
          {label}
        </Text>
        {labelSmall && (
          <Text
            as={"span"}
            css={css`
              @media (min-width: ${mediaTabletPortrait}) {
                display: none;
              }
            `}
            size={size}
            color={"inherit"}
            weight={"medium"}
          >
            {labelSmall}
          </Text>
        )}
        {icon && <Icon source={icon} size={getIconSize(size)} yPos={"1px"} />}
      </Flex>
    </Anchor>
  );
};

export default Link;
