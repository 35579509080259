import {
  borderRadiusMedium,
  borderStandard,
  colorBlueDark,
  colorBlueLight,
  colorBlueMedium,
  colorGrayLightest,
  colorSteelDarkest,
  colorWhite,
  layoutWidth1200,
  mediaTabletLandscape,
  sizeLarge,
  sizeMedium,
  sizeXlarge,
  sizeXsmall,
  sizeXxlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, RefObject } from "react";
import { useEffect, useRef, useState } from "react";
import type { IconSource } from "@10xdev/types/models/IconSource";

import Anchor from "../../Anchor";
import DocumentCard from "../../DocumentCard";
import Heading from "../../Heading";
import Icon from "../../Icon";
import PublicationCard from "../../PublicationCard";
import Text from "../../Text";
import VideoCard from "../../VideoCard";

interface Props {
  sections: Array<any>;
  title?: string;
}

const TRANSITION = "all 0.15s ease-in-out";

const Results: FunctionComponent<Props> = ({ sections, title }) => {
  const cards: Record<string, FunctionComponent<any>> = {
    documents: DocumentCard,
    publications: PublicationCard,
    videos: VideoCard,
  };

  const icons: Record<string, IconSource> = {
    documents: "file-alt-light",
    publications: "book-open",
    videos: "video",
  };

  const refs: Record<string, RefObject<HTMLElement>> = {
    documents: useRef<HTMLElement>(null),
    publications: useRef<HTMLElement>(null),
    videos: useRef<HTMLElement>(null),
  };

  const [height, setHeight] = useState(0);

  const [sectionIndex, setSectionIndex] = useState(0);
  const currentSection = sections[sectionIndex];
  const handleSectionClick = (index: number) => setSectionIndex(index);
  const ref = refs[currentSection.type];

  useEffect(() => {
    const scrollHeight = ref?.current?.scrollHeight;
    if (typeof scrollHeight !== "undefined") {
      setHeight(scrollHeight);
    }
  }, [currentSection.type, ref]);

  return (
    <div
      className={"Results"}
      css={css`
        margin: 0 auto;
        max-width: ${layoutWidth1200};
        padding: 2rem 1rem;
      `}
    >
      <div
        css={css`
          display: flex;
          margin: 0 auto;
          min-height: 600px;
          padding: 2rem 0 2.5rem;

          ::before {
            border-top: 1px solid ${colorBlueMedium};
            content: "";
            position: absolute;
            transform: translate(-48px, 16px) rotate(120deg);
            transform-origin: 0% 0%;
            width: 300px;
          }

          @media (max-width: ${mediaTabletLandscape}) {
            display: none;
          }
        `}
      >
        <div
          css={css`
            flex-basis: 40%;
          `}
        >
          <Heading
            as={"h3"}
            css={css`
              margin-bottom: ${sizeXxlarge};
            `}
            responsive={true}
            size={"xxxlarge"}
          >
            {title}
          </Heading>

          {sections?.map((section, index) => {
            const { description, link, title, type } = section;
            const isActive = currentSection.type === type;

            return (
              <div
                css={css`
                  margin-right: 4rem;
                `}
                key={description}
              >
                <div
                  css={css`
                    background-color: ${isActive ? colorBlueMedium : "none"};
                    border-radius: ${borderRadiusMedium};
                    color: ${isActive ? "white" : "inherit"};
                    display: block;
                    padding: ${sizeLarge} ${sizeXlarge} ${sizeLarge};
                    text-align: left;
                    transition: ${TRANSITION};

                    :hover :first-of-type {
                      color: ${isActive ? colorWhite : colorBlueMedium};
                    }
                  `}
                >
                  <button
                    css={css`
                      align-items: center;
                      background: none;
                      border: none;
                      color: ${isActive ? colorWhite : colorSteelDarkest};
                      cursor: pointer;
                      display: flex;
                      margin-bottom: 0.75rem;
                      outline: none;
                      transition: ${TRANSITION};
                      width: 100%;
                    `}
                    id={`filter-button-${index + 1}`}
                    onClick={() => handleSectionClick(index)}
                  >
                    <div
                      css={css`
                        color: ${isActive ? "inherit" : colorBlueMedium};
                      `}
                    >
                      <Icon
                        color={"inherit"}
                        css={{ marginRight: sizeLarge }}
                        size={"28px"}
                        source={type ? icons[type] : "book-open"}
                        yPos={"3px"}
                      />
                    </div>
                    <Heading
                      as={"h4"}
                      color={"inherit"}
                      css={css`
                        margin-bottom: 0;
                      `}
                      size={"large"}
                    >
                      {title}
                    </Heading>
                  </button>

                  <section
                    css={css`
                      color: ${isActive ? colorWhite : "inherit"};
                      height: ${isActive ? height + "px" : 0};
                      opacity: ${isActive ? 1 : 0.5};
                      overflow: hidden;
                      padding-left: 3.5rem;
                      padding-right: 4rem;
                      transition: ${TRANSITION};
                    `}
                    ref={refs[type]}
                  >
                    <Text
                      as={"p"}
                      color={"inherit"}
                      css={css`
                        margin-bottom: ${sizeMedium};
                      `}
                      size={"small"}
                    >
                      {description}
                    </Text>

                    <Anchor
                      aria-label={`View all ${title}`}
                      color={"inherit"}
                      hoverColor={colorBlueLight}
                      href={link.url}
                    >
                      <Text
                        as={"span"}
                        color={"white"}
                        size={"small"}
                        weight={"semibold"}
                      >
                        {link.label}
                        <Icon
                          size={"12px"}
                          source={"nav-right"}
                          xPos={"12px"}
                          yPos={"1px"}
                        />
                      </Text>
                    </Anchor>
                  </section>
                </div>
              </div>
            );
          })}
        </div>

        <div
          css={css`
            flex-basis: 60%;
          `}
        >
          {currentSection?.items?.slice(0, 3).map((item: any) => {
            const CardComponent = cards[currentSection.type];
            return (
              <CardComponent
                background={colorGrayLightest}
                hover={true}
                key={item.title}
                {...item}
              />
            );
          })}

          <div
            css={css`
              display: flex;
              justify-content: center;
              margin-top: 3rem;
            `}
          >
            <Text as={"span"} color={"blue"} size={"small"} weight={"semibold"}>
              <Anchor
                color={"inherit"}
                hoverColor={colorBlueDark}
                href={currentSection?.link?.url}
              >
                {currentSection?.link?.label}
                <Icon
                  size={"12px"}
                  source={"nav-right"}
                  xPos={"12px"}
                  yPos={"1px"}
                />
              </Anchor>
            </Text>
          </div>
        </div>
      </div>

      <div
        css={css`
          display: none;
          padding-top: 1rem;

          ::before {
            border-top: 2px solid ${colorBlueMedium};
            content: "";
            position: absolute;
            transform: translate(-16px, 16px) rotate(120deg);
            transform-origin: 0% 0%;
            width: 300px;
          }

          @media (max-width: ${mediaTabletLandscape}) {
            display: block;
            margin: 0 auto 6rem;
          }
        `}
      >
        <Heading
          as={"h3"}
          css={css`
            margin-bottom: 0;
          `}
          size={"xxlarge"}
        >
          {title}
        </Heading>

        {sections.map((section, index) => {
          const { description, title } = section;
          return (
            <div
              css={css`
                border-bottom: ${borderStandard};
                padding: 4rem 0;
              `}
              key={index}
            >
              <Heading
                as={"h4"}
                css={css`
                  margin-bottom: ${sizeXsmall};
                `}
                size={"large"}
              >
                {title}
              </Heading>
              <Text
                as={"p"}
                css={css`
                  margin-bottom: ${sizeMedium};
                `}
                size={"small"}
              >
                {description}
              </Text>

              <Text
                as={"div"}
                color={"blue"}
                css={css`
                  margin-bottom: 2.5rem;
                `}
                size={"small"}
                weight={"semibold"}
              >
                <Anchor color={"inherit"} href={currentSection?.link?.url}>
                  {currentSection?.link?.label}
                  <Icon
                    size={"12px"}
                    source={"nav-right"}
                    xPos={"12px"}
                    yPos={"1px"}
                  />
                </Anchor>
              </Text>

              <div
                css={css`
                  display: flex;
                  overflow-x: scroll;
                  overflow-y: hidden;
                `}
              >
                {section?.items?.map((item: any) => {
                  const Card = cards[section.type];
                  return (
                    <Card
                      background={colorGrayLightest}
                      hover={false}
                      key={item.title}
                      {...item}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Results;
