import {
  borderRadiusLarge,
  colorWhite,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../../../Anchor/Anchor";
import Icon from "../../../Icon/Icon";
import type { IconSource } from "@10xdev/types/models/IconSource";
import Image from "../../../Image/Image";
import Text from "../../../Text/Text";

interface Props {
  description: string;
  image: {
    alt: string;
    src: string;
  };
  links: {
    icon: IconSource;
    label: string;
    url: string;
  }[];
  title: string;
}

const SCAssayCard = ({ description, image, links, title }: Props) => {
  return (
    <div
      css={css`
        align-items: start;
        background: ${colorWhite};
        border-radius: ${borderRadiusLarge};
        box-shadow: 0px 1.7px 4.3px 0px rgba(0, 0, 0, 0.06);
        box-shadow: 0px 1.7px 4.3px 0px color(display-p3 0 0 0 / 0.06);
        box-sizing: border-box;
        display: grid;
        gap: 1rem;
        overflow: hidden;
        padding: 1.5rem;
        @media (min-width: ${mediaTabletLandscape}) {
          max-width: 360px;
          flex: 1 0 360px;
        }
      `}
    >
      {image ? <Image alt={image.alt} src={image.src} /> : null}
      {title ? (
        <Text as={"h4"} size={"large"} weight={"semibold"}>
          {title}
        </Text>
      ) : null}
      <Text as={"p"} color={"midgray"} size={"small"}>
        {description}
      </Text>

      {links.length ? (
        <div
          css={css`
            align-self: end;
            display: grid;
          `}
        >
          {links.map((link) => {
            return (
              <Anchor
                css={css`
                  align-items: center;
                  display: flex;
                  gap: 0.75rem;
                  height: 2rem;
                `}
                href={link.url}
                key={link.url}
              >
                <Text
                  as={"span"}
                  color={"inherit"}
                  size={"small"}
                  weight={"semibold"}
                >
                  {link.label}
                </Text>
                <Icon
                  color={"inherit"}
                  size={"12px"}
                  source={link.icon}
                  yPos={"1px"}
                />
              </Anchor>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default SCAssayCard;
