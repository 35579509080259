import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css, Global } from "@emotion/react";
import { debounce } from "lodash-es";
import { Fragment, useEffect, useRef, useState } from "react";
import { LinkedInEmbed } from "react-social-media-embed";
import { Tweet } from "react-tweet";

import Button from "../../Button";
import Icon from "../../Icon";
import Text from "../../Text";
import type { Props as ContainerProps } from "../Section/Layout/Container";
import Container from "../Section/Layout/Container";

type Social = "twitter" | "linkedin";

interface Props {
  container: ContainerProps;
  gap: number;
  postHeight: number;
  postWidth: number;
  posts: {
    postUrl?: string;
    type: Social;
    url: string;
  }[];
  title: string;
}

const SocialCarousel = ({
  container,
  gap = 32,
  posts,
  postHeight = 800,
  postWidth = 430,
  title,
}: Props) => {
  const [offSet, setOffSet] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);

  const handlePreviousClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scroll({
        left: carouselRef.current.scrollLeft - postWidth - gap,
        behavior: "smooth",
      });
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scroll({
        left: carouselRef.current.scrollLeft + postWidth + gap,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = 0;
    }
  }, []);

  const handleScrollDebounced = debounce((event) => {
    setOffSet((event.target as HTMLDivElement).scrollLeft);
  }, 100);

  return (
    <Container {...container}>
      <Text
        as={"p"}
        css={css`
          margin: 0;
        `}
        size={"xxlarge"}
        weight={"semibold"}
      >
        {title}
      </Text>

      <div
        css={css`
          position: relative;
          overflow-x: hidden;
        `}
      >
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr;
            gap: ${gap}px;
            overflow-x: scroll;
            padding-bottom: 2rem;
            width: 100%;

            @media (min-width: ${mediaTabletLandscape}) {
              margin-bottom: 1.5rem;
              overflow: scroll;
              overscroll-behavior-x: contain;
              padding-bottom: 0;
              grid-template-columns: repeat(${posts.length}, ${postWidth}px);

              > :last-child {
                padding-right: 120px;
              }
            }
          `}
          data-theme={"light"}
          ref={carouselRef}
          onScroll={handleScrollDebounced}
        >
          <Global
            styles={css`
              div[data-theme="light"] .react-tweet-theme {
                margin: 0;
              }
            `}
          />
          {posts.map((post) => {
            const socialCard: Record<Social, any> = {
              linkedin: (
                <LinkedInEmbed
                  height={postHeight}
                  postUrl={post.postUrl}
                  url={post.url}
                  width={"100%"}
                />
              ),
              twitter: <Tweet id={post.url.split("/status/")[1]} />,
            };
            return <Fragment key={post.url}>{socialCard[post.type]}</Fragment>;
          })}
        </div>
        <div
          css={css`
            align-items: center;
            background: linear-gradient(
              270deg,
              rgba(255, 255, 255, 0) 0%,
              #ffffff 86.24%
            );
            display: none;
            height: 100%;
            justify-content: start;
            position: absolute;
            top: 0;
            width: 120px;

            @media (min-width: ${mediaTabletLandscape}) {
              display: flex;
              opacity: ${offSet > 0 ? "1" : "0"};
              transition: opacity 0.4s cubic-bezier(0.77, 0.17, 0.21, 0.81);
            }
          `}
        >
          <Button
            background={"gray"}
            color={"white"}
            css={css`
              border-radius: 50%;
              height: 4rem;
              margin-left: 1rem;
              width: 4rem;
            `}
            onClick={handlePreviousClick}
          >
            <Icon size={"22px"} source={"nav-left"} xPos={"-1px"} />
          </Button>
        </div>
        <div
          css={css`
            align-items: center;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 0%,
              #ffffff 86.24%
            );
            display: none;
            height: 100%;
            justify-content: end;
            left: 100%;
            position: absolute;
            top: 0;
            transform: translateX(-100%);
            width: 120px;

            @media (min-width: ${mediaTabletLandscape}) {
              display: flex;
            }
          `}
        >
          <Button
            background={"gray"}
            color={"white"}
            css={css`
              border-radius: 50%;
              display: none;
              height: 4rem;
              margin-right: 1rem;
              width: 4rem;

              @media (min-width: ${mediaTabletLandscape}) {
                display: flex;
              }

              &:disabled > svg {
                color: white;
              }
            `}
            onClick={handleNextClick}
            disabled={
              offSet + (carouselRef.current?.clientWidth || 0) >=
              (carouselRef.current?.scrollWidth || 0)
            }
          >
            <Icon size={"22px"} source={"nav-right"} xPos={"1px"} />
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default SocialCarousel;
