import { css } from "@emotion/react";
import Publication from "../../ProductPage/cards/publication";
import SectionHeader from "../../ProductPage/SectionHeader";
import Grid from "../../Grid";
import Repeater from "../../ProductPage/Repeater";

import type { IconSource } from "@10xdev/types/models/IconSource";
import type { Props as Header } from "../../ProductPage/SectionHeader";

interface Props {
  header?: Header;
  publications: {
    title?: string;
    journal?: string;
    author?: string;
    date?: string;
    link?: {
      label?: string;
      icon?: IconSource;
      url?: string;
    };
  }[];
}

const Publications = ({ header, publications }: Props) => {
  return (
    <Grid
      css={css`
        max-width: 1200px;
        width: 100%;
      `}
      gap={"3.5rem"}
      padding={{ base: "0 1rem 2rem", tabletPortrait: "0 1rem 4.5rem" }}
    >
      {header ? <SectionHeader {...header} /> : null}
      {publications.length ? (
        <Repeater card={Publication} list={publications} />
      ) : null}
    </Grid>
  );
};

export default Publications;
