import { css } from "@emotion/react";

import Grid from "../../../Grid";
import type { IconSource } from "@10xdev/types/models/IconSource";
import Image from "../../../Image";
import Text from "../../../Text";
import Banner from "../../Masthead/Banner";
import Notification from "../../Masthead/Notification";
import CTAs from "./CTAs";
import KeyMetrics from "./KeyMetrics";

export type Link = {
  label: string;
  icon?: IconSource;
  url: string;
};

export type KeyMetric = {
  primary?: string;
  secondary?: string;
  tertiary?: string;
};

interface Props {
  /** Paragraph text. */
  description?: string;

  /** Text for the eyebrow. */
  eyebrow?: string;

  /** Hero image. */
  heroImage?: {
    src: string;
    alt: string;
  };

  /** List of metrics to highlight. */
  keyMetrics?: KeyMetric[];

  /** A list of CTA links */
  links: Link[];

  /** An optional notification banner message with link . */
  notification?: {
    background?: "base" | "blue" | "white";
    target?: "_self" | "_blank";
    text: string;
    url: string;
  };

  /** Large text. */
  title?: string | JSX.Element;
}

const ProductPageMasthead = ({
  description,
  eyebrow,
  heroImage,
  links,
  keyMetrics,
  notification,
  title,
}: Props) => {
  return (
    <Grid
      css={css`
        background: linear-gradient(180deg, #e9f4fd 0.4%, #fff 100%);
        background: linear-gradient(
          180deg,
          color(display-p3 0.9207 0.9566 0.99) 0.4%,
          color(display-p3 1 1 1) 100%
        );
        width: 100%;
      `}
      justifyContent={"center"}
      gap={{ base: "2rem", tabletPortrait: "5.5rem" }}
      padding={{ base: "0 1rem 2rem", tabletPortrait: "0 1rem 4.5rem" }}
    >
      <div>
        {notification?.text ? (
          <Notification
            background={notification.background}
            target={notification.target}
            text={notification.text}
            url={notification.url}
          />
        ) : null}
        <Banner mode={"light"} />
      </div>

      <Grid
        css={css`
          max-width: 1200px;
        `}
        gridTemplateColumns={{ base: "1fr", tabletLandscape: "52.5% 1fr" }}
        gap={"2rem"}
      >
        <Grid alignContent={"start"} gap={"2rem"}>
          <Text as={"h1"} color={"success"} size={"large"} weight={"semibold"}>
            {eyebrow}
          </Text>
          <Text as={"h2"} size={"xxxlarge"} weight={"semibold"}>
            {title}
          </Text>

          <Grid gap={"1rem"}>
            <Text as={"p"} color={"midgray"} size={"medium"}>
              {description}
            </Text>
            {links?.length ? <CTAs links={links} /> : null}
          </Grid>

          {keyMetrics ? <KeyMetrics keyMetrics={keyMetrics} /> : null}
        </Grid>

        {heroImage ? (
          <Image
            css={css`
              @media (max-width: 900px) {
                display: none;
              }
            `}
            src={heroImage.src}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ProductPageMasthead;
