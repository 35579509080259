import { colorBlueDark, mediaTabletLandscape } from "@10xdev/design-tokens";
import type { LinkItem } from "@10xdev/types/models/LinkItem";
import { css } from "@emotion/react";
import { kebabCase } from "lodash-es";
import type { FunctionComponent } from "react";

import Anchor from "../../../Anchor";
import Icon from "../../../Icon";
import Image from "../../../Image";
import Text from "../../../Text";
import type { Bullet, ButtonType } from "../types";
import Header from "./Header";

interface Props {
  activeTab: string | string[] | undefined;
  button?: ButtonType;
  description?: string;
  link?: LinkItem;
  list?: Array<Bullet>;
  title?: string;
}

const BulletList: FunctionComponent<Props> = ({
  activeTab,
  button,
  description,
  link,
  list,
  title,
}) => {
  const slug = kebabCase(title);
  return (
    <div
      css={css`
        display: ${activeTab === slug ? "block" : "none"};
        margin-bottom: 1.5rem;
        padding: 2rem;
        @media (min-width: ${mediaTabletLandscape}) {
          padding: 2.5rem 3.5rem;
        }
      `}
      id={slug}
    >
      <Header
        button={button}
        description={description}
        link={link}
        title={title}
      />

      <ul
        css={css`
          list-style: none;
          margin: 2rem 0;
          padding: 0;

          @media (min-width: ${mediaTabletLandscape}) {
            display: flex;
          }
        `}
      >
        {list?.length
          ? list.map((item: Bullet) => {
              const { description, link, title } = item;
              return (
                <li
                  css={css`
                    display: flex;
                    flex-basis: 33%;
                    flex-direction: column;
                    gap: 4rem;
                    justify-content: space-between;

                    @media (min-width: ${mediaTabletLandscape}) {
                      gap: 0;
                      margin-right: 3rem;
                    }
                  `}
                  key={title}
                >
                  <div>
                    <Image
                      alt={"checkmark"}
                      css={css`
                        margin-bottom: 1rem;
                      `}
                      src={
                        "https://cdn.10xgenomics.com/image/upload/v1588968667/icons/checkmark.svg"
                      }
                      width={"32px"}
                    />
                    <Text
                      as={"div"}
                      css={css`
                        margin-bottom: 0.5rem;
                      `}
                      size={"medium"}
                      weight={"semibold"}
                    >
                      {title}
                    </Text>
                    <Text
                      as={"p"}
                      color={"midgray"}
                      css={css`
                        margin-bottom: ${link ? "1.5rem" : 0};
                      `}
                      size={"medium"}
                    >
                      {description}
                    </Text>
                  </div>

                  {link?.url ? (
                    <Text
                      as={"div"}
                      color={"blue"}
                      size={"small"}
                      weight={"semibold"}
                    >
                      <Anchor
                        color={"inherit"}
                        hoverColor={colorBlueDark}
                        href={link.url}
                        target={link.target}
                      >
                        {link.label}
                        {link.type ? (
                          <Icon
                            size={"12px"}
                            source={link.type}
                            xPos={"16px"}
                            yPos={"1px"}
                          />
                        ) : null}
                      </Anchor>
                    </Text>
                  ) : null}
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
};

export default BulletList;
