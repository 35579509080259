import { css } from "@emotion/react";
import { useRouter } from "next/router";
import Grid from "../../Grid";
import type { Props as Header } from "../../ProductPage/SectionHeader";
import SectionHeader from "../../ProductPage/SectionHeader";
import ExperimentBuilder from "../../ProductPage/cards/ExperimentBuilder";
import type { IconSource } from "@10xdev/types/models/IconSource";
import ProductCard from "../../ProductPage/Catalog/ProductCard";
import type { ProductCardDetails } from "../../ProductPage/Catalog/types";
import {
  chromium3Prime,
  chromium5Prime,
  chromiumFlex,
  epiAtac,
  epiMultiome,
  chromiumX,
  chromiumIx,
  chromiumXo,
} from "../../ProductPage/Catalog/constants";

import {
  colorWhite,
  borderStandard,
  borderRadiusMedium,
} from "@10xdev/design-tokens";

export type Callout = {
  title: string;
  image: {
    alt: string;
    src: string;
  };
  description: string;
  cta: {
    label: string;
    icon: IconSource;
    url: string;
  };
};

interface Props {
  header: Header;
  callout: Callout;
  slug: string;
}

const productDetails: Record<string, ProductCardDetails> = {
  ["universal-three-prime-gene-expression"]: chromium3Prime,
  ["universal-five-prime-gene-expression"]: chromium5Prime,
  ["flex-gene-expression"]: chromiumFlex,
  ["epi-atac"]: epiAtac,
  ["epi-multiome"]: epiMultiome,
  ["chromium-x"]: chromiumX,
  ["chromium-ix"]: chromiumIx,
  ["chromium-xo"]: chromiumXo,
};

const AssayComponents = ({ header, callout, slug }: Props) => {
  const router = useRouter();
  const product =
    router.locale === "cn"
      ? {
          ...productDetails[slug],
          versions: productDetails[slug].versions.filter(
            ({ name }) =>
              !["v3 GEM-X Multiplex", "v4 GEM-X Multiplex"].includes(name),
          ),
        }
      : productDetails[slug];
  return (
    <Grid
      css={css`
        max-width: 1200px;
        width: 100%;
      `}
      gap={"1.5rem"}
      padding={"2rem 1rem"}
    >
      {header ? <SectionHeader {...header} /> : null}
      <Grid
        css={css`
          background-color: ${colorWhite};
          border: ${borderStandard};
          border-radius: ${borderRadiusMedium};
          box-shadow: 0px 1.72px 4.29px 0px rgba(0, 0, 0, 0.06);
        `}
        padding={{ base: "1rem", tabletPortrait: "2.5rem" }}
      >
        <ProductCard
          hideName
          showCompatibleProducts
          handleSelectProduct={async () => {}}
          isSearched={false}
          product={product}
        />
      </Grid>
      {callout ? <ExperimentBuilder {...callout} /> : null}
    </Grid>
  );
};

export default AssayComponents;
