import {
  borderStandard,
  colorBlueDark,
  colorBlueMedium,
  layoutWidth720,
  layoutWidth1200,
  mediaPhoneOnly,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import type { LinkItem } from "@10xdev/types/models/LinkItem";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Paragraph from "../../Paragraph";
import Text from "../../Text";
import WistiaVideo from "../../WistiaVideo";

interface Topic {
  links: Array<LinkItem>;
  title: string;
}

interface Props {
  description?: string;
  title?: string;
  topics?: Array<Topic>;
  wistiaID: string;
}

const Info: FunctionComponent<Props> = ({
  description,
  title,
  topics,
  wistiaID,
}) => {
  return (
    <div
      className={"Info"}
      css={css`
        margin: 0 auto;
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem 0;

        ::before {
          border-top: 1px solid ${colorBlueMedium};
          content: "";
          position: absolute;
          transform: translate(-48px, 0) rotate(120deg);
          transform-origin: 0% 0%;
          width: 300px;
        }

        @media (max-width: ${mediaTabletLandscape}) {
          padding-top: 3.5rem;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          padding-bottom: 5.25rem;

          @media (max-width: ${mediaTabletLandscape}) {
            display: block;
            margin: 0 auto;
            max-width: ${layoutWidth720};
          }
        `}
      >
        <div
          css={css`
            margin-right: 10%;
            width: 40%;

            @media (max-width: ${mediaTabletLandscape}) {
              margin-bottom: 3rem;
              width: 100%;
            }
          `}
        >
          <Heading
            as={"h3"}
            css={css`
              margin-bottom: 2rem;
            `}
            responsive={true}
            size={"xxxlarge"}
          >
            {title}
          </Heading>
          <Paragraph responsive={true} size={"medium"}>
            {description}
          </Paragraph>
        </div>

        <div
          css={css`
            width: 50%;

            @media (max-width: ${mediaTabletLandscape}) {
              width: 100%;
            }
          `}
        >
          <WistiaVideo wistiaID={wistiaID} />
        </div>
      </div>

      {topics ? (
        <div
          css={css`
            padding-bottom: 4rem;

            @media (max-width: ${mediaTabletLandscape}) {
              display: block;
              max-width: ${layoutWidth720};
            }
          `}
        >
          {topics?.map((topic) => {
            const { links, title } = topic;
            return (
              <div
                css={css`
                  border-top: ${borderStandard};
                  box-sizing: border-box;
                  display: flex;
                  justify-content: space-between;
                  padding-bottom: 3rem;
                  padding-top: 3rem;

                  :last-of-type {
                    padding-bottom: 0;
                  }

                  @media (max-width: ${mediaPhoneOnly}) {
                    display: block;
                  }
                `}
                key={title}
              >
                <div
                  css={css`
                    width: 40%;

                    @media (max-width: ${mediaPhoneOnly}) {
                      width: 100%;
                    }
                  `}
                >
                  <Heading as={"h4"} responsive={true} size={"xlarge"}>
                    {title}
                  </Heading>
                </div>
                <ul
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 1.125rem;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    width: 60%;

                    @media (max-width: ${mediaPhoneOnly}) {
                      width: 100%;
                    }
                  `}
                >
                  {links?.map((link) => {
                    const { label, type, url } = link;
                    return (
                      <>
                        {url ? (
                          <li key={label}>
                            <Text
                              as={"div"}
                              color={"blue"}
                              size={"small"}
                              weight={"medium"}
                            >
                              <Anchor
                                color={"inherit"}
                                hoverColor={colorBlueDark}
                                href={url}
                              >
                                {label}
                                {type ? (
                                  <Icon
                                    size={"12px"}
                                    source={type}
                                    xPos={"12px"}
                                    yPos={"2px"}
                                  />
                                ) : null}
                              </Anchor>
                            </Text>
                          </li>
                        ) : null}
                      </>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default Info;
